import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

import { TextWrapper } from "./YesNoDialog.style";

type YesNoDialogProps = {
  title?: string;
  text: string | JSX.Element | React.ReactNode;
  open: boolean;
  onYes: () => void;
  onNo: () => void;
  yesText?: string;
  noText?: string;
};

export const YesNoDialog = React.memo(
  ({
    title = "Confirm",
    text,
    open,
    onYes,
    onNo,
    yesText,
    noText,
  }: YesNoDialogProps) => (
    <Dialog aria-labelledby="confirm-title" open={open}>
      <DialogTitle id="confirm-title">{title}</DialogTitle>
      <DialogContent dividers>
        <TextWrapper>{text}</TextWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onYes} color="secondary" variant="outlined" autoFocus>
          {yesText ?? "Yes"}
        </Button>
        <Button onClick={onNo} color="primary" variant="outlined">
          {noText ?? "No"}
        </Button>
      </DialogActions>
    </Dialog>
  )
);

export default YesNoDialog;
