type AffixContainerProps = {
  isPrefix: boolean;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  affix?: JSX.Element;
  children: JSX.Element | JSX.Element[];
};

export const AffixContainer = ({
  isPrefix,
  prefix,
  suffix,
  affix,
  children,
}: AffixContainerProps) => (
  <>
    {isPrefix ? prefix ?? affix : <></>}
    {children}
    {!isPrefix ? suffix ?? affix : <></>}
  </>
);
