import { Box, Collapse } from "@material-ui/core";
import _ from "lodash";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { RootState } from "src/redux";
import { bulkUpdateLayer } from "src/redux/reducers/layerReducer";
import { GroupObjLayerData } from "src/types/common";
import { BuilderLayerJSON } from "src/types/query";

import LayerItem, { LayerItemProps } from "../LayerItem/LayerItem";
export type LayerGroupItemProps = LayerItemProps & {
  layerItem: BuilderLayerJSON<GroupObjLayerData>;
  disableDnd?: boolean;
};

export const LayerGroupItem = React.memo(
  ({ layerItem, ...props }: LayerGroupItemProps) => {
    const dispatch = useDispatch();
    const layerList = useSelector(
      (state: RootState) => state.layerReducer.list
    );
    const isGroupExpanded = !props.groupCompression[layerItem.id];
    const layerIds = layerItem.layer_data.layers ?? [];
    const sortedList = _.orderBy(
      layerList.filter((item) => layerIds.includes(item.id)),
      ["layer_order"],
      ["asc"]
    );

    const handleChangeLayerOrders = useCallback(
      (list: BuilderLayerJSON[]) => {
        const layers = [];
        for (const index in list) {
          const targetOrder = parseInt(index) + layerItem.layer_order + 1;
          if (list[index].layer_order !== targetOrder) {
            layers.push({
              id: list[index].id,
              layer_order: targetOrder,
            });
          }
        }
        if (layers.length) {
          dispatch(bulkUpdateLayer(layers, { pushingToHistory: false }));
        }
      },
      [layerItem.layer_order, dispatch]
    );

    return (
      <Box>
        <LayerItem layerItem={layerItem} {...props} />

        <Collapse in={isGroupExpanded}>
          <Box pl={3}>
            <ReactSortable
              list={sortedList}
              setList={handleChangeLayerOrders}
              animation={150}
              sort={!props.disableDnd && !props.disabled}
            >
              {sortedList.map((item) => (
                <LayerItem key={item.id} layerItem={item} {...props} />
              ))}
            </ReactSortable>
          </Box>
        </Collapse>
      </Box>
    );
  }
);

export default LayerGroupItem;
