import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { withAuthGuard } from "src/hooks";
import { withGAPageTracking } from "src/hooks/withGAPageTracking";
import AuthLayout from "src/layouts/Auth";
import FallbackLayout from "src/layouts/Fallback";
import MainLayout from "src/layouts/Main";

import { Page404 } from "../pages/fallback/Page404";
import {
  authLayoutRoutes,
  fallbackLayoutRoutes,
  mainLayoutRoutes,
  RouteItem,
} from "./index";

const renderChildRoutes = (
  Layout: React.ComponentType,
  routes: RouteItem[]
): React.ReactNode =>
  routes.map(
    (
      {
        path,
        component: Component,
        children,
        guarded,
        redirectToSignIn,
        adminOnly,
      },
      index
    ) => {
      const ComponentLayout = withGAPageTracking(
        guarded ? withAuthGuard(Layout, redirectToSignIn, adminOnly) : Layout
      );

      return children ? (
        renderChildRoutes(Layout, children)
      ) : Component ? (
        <Route
          key={index}
          path={path}
          Component={() => (
            <ComponentLayout>
              <Component />
            </ComponentLayout>
          )}
        />
      ) : null;
    }
  );

const AllRoutes = () => (
  <BrowserRouter>
    <Routes>
      {renderChildRoutes(MainLayout, mainLayoutRoutes)}
      {renderChildRoutes(AuthLayout, authLayoutRoutes)}
      {renderChildRoutes(FallbackLayout, fallbackLayoutRoutes)}
      <Route path="/scheme/:id" element={<Navigate to="/" replace />} />
      <Route
        Component={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Routes>
  </BrowserRouter>
);

export default AllRoutes;
