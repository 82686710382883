import { Box, IconButton } from "@material-ui/core";
import { Stage } from "konva/lib/Stage";
import React, { RefObject, useEffect } from "react";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLayer } from "src/hooks";
import { RootState } from "src/redux";
import { setShowProperties } from "src/redux/reducers/boardReducer";
import {
  CloneLayerListOptions,
  CloneLayerOptions,
} from "src/redux/reducers/layerReducer";
import { GroupObjLayerData, MovableObjLayerData } from "src/types/common";
import { BuilderLayerJSON } from "src/types/query";

import { LayerProperty } from "./LayerProperty";
import { MultiLayerProperty } from "./MultiLayerProperty";
import { SchemeProperty } from "./SchemeProperty";

const Wrapper = React.memo(({ children }: { children?: React.ReactNode }) => {
  const showProperties = useSelector(
    (state: RootState) => state.boardReducer.showProperties
  );

  if (!showProperties) {
    return <></>;
  }

  return (
    <Box
      position="absolute"
      display="flex"
      overflow="visible"
      width="100%"
      height="300px"
      bottom={0}
      zIndex={1202}
      flexDirection="column"
      bgcolor="#666"
    >
      {children}
    </Box>
  );
});

export interface MobilePropertyBarProps {
  editable: boolean;
  stageRef: RefObject<Stage>;
  transformingLayer?: BuilderLayerJSON | null;
  onDeleteLayers: (layers: BuilderLayerJSON[]) => void;
  onCloneLayer: (
    layer: BuilderLayerJSON<MovableObjLayerData>,
    options?: CloneLayerOptions
  ) => void;
  onCloneLayerList: (
    layers: BuilderLayerJSON<MovableObjLayerData>[],
    options?: CloneLayerListOptions
  ) => void;
  onGroupLayers: (layers: BuilderLayerJSON[]) => void;
  onUngroupLayer: (layer: BuilderLayerJSON<GroupObjLayerData>) => void;
}

export const MobilePropertyBar = React.memo(
  ({
    editable,
    stageRef,
    transformingLayer,
    onCloneLayer,
    onCloneLayerList,
    onDeleteLayers,
    onGroupLayers,
    onUngroupLayer,
  }: MobilePropertyBarProps) => {
    const dispatch = useDispatch();

    const { currentLayer, selectedLayers } = useLayer();
    const currentScheme = useSelector(
      (state: RootState) => state.schemeReducer.current
    );

    const hidePropertyBar = () => {
      dispatch(setShowProperties(false));
    };

    useEffect(() => {
      dispatch(setShowProperties(false));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Wrapper>
        <IconButton
          style={{
            width: "100%",
            borderRadius: "0px",
            background: "black",
          }}
          onClick={hidePropertyBar}
        >
          <BsChevronDoubleDown />
        </IconButton>

        <Box
          overflow="auto"
          py={1}
          px={2}
          height="100%"
          width="100%"
          position="relative"
        >
          {currentLayer ? (
            <LayerProperty
              editable={editable}
              stageRef={stageRef}
              transformingLayer={transformingLayer}
              onClone={onCloneLayer}
              onDelete={onDeleteLayers}
              onUngroup={onUngroupLayer}
            />
          ) : selectedLayers.length > 1 ? (
            <MultiLayerProperty
              editable={editable}
              onCloneLayerList={onCloneLayerList}
              onGroup={onGroupLayers}
              onDelete={onDeleteLayers}
            />
          ) : currentScheme ? (
            <SchemeProperty editable={editable} />
          ) : (
            <></>
          )}
        </Box>
      </Wrapper>
    );
  }
);

export default MobilePropertyBar;
