import { Box, Button, Divider, Popover, Typography } from "@material-ui/core";
import LinkOffIcon from "@material-ui/icons//LinkOff";
import FlipIcon from "@material-ui/icons/Flip";
import LinkIcon from "@material-ui/icons/Link";
import { Stage } from "konva/lib/Stage";
import React, { RefObject, useCallback, useEffect } from "react";
import { ImCopy } from "react-icons/im";
import {
  MdDelete,
  MdLock,
  MdRotateRight,
  MdSwapHoriz,
  MdSwapVert,
  MdVisibilityOff,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  decodeHtml,
  focusBoardQuickly,
  isCenterBasedShape,
  rotateAroundCenter,
} from "src/helper";
import { useLayer } from "src/hooks";
import { RootState } from "src/redux";
import { setContextMenu } from "src/redux/reducers/boardReducer";
import {
  CloneLayerListOptions,
  CloneLayerOptions,
  updateLayer,
} from "src/redux/reducers/layerReducer";
import {
  GroupObjLayerData,
  MovableObjLayerData,
  Position,
  ShapeBaseObjLayerData,
} from "src/types/common";
import { LayerTypes } from "src/types/enum";
import { BuilderLayerJSON } from "src/types/query";
import styled from "styled-components";

type BoardContextMenuProps = {
  stageRef: RefObject<Stage | undefined>;
  wrapperPosition: Position;
  onDeleteLayers: (layer: BuilderLayerJSON[]) => void;
  onCloneLayer: (
    layer: BuilderLayerJSON<MovableObjLayerData>,
    options?: CloneLayerOptions
  ) => void;
  onCloneLayerList: (
    layers: BuilderLayerJSON<MovableObjLayerData>[],
    options?: CloneLayerListOptions
  ) => void;
  onGroupLayers: (layers: BuilderLayerJSON[]) => void;
  onUngroupLayer: (layer: BuilderLayerJSON<GroupObjLayerData>) => void;
};

export const BoardContextMenu = React.memo(
  ({
    stageRef,
    wrapperPosition,
    onDeleteLayers,
    onCloneLayer,
    onCloneLayerList,
    onGroupLayers,
    onUngroupLayer,
  }: BoardContextMenuProps) => {
    const dispatch = useDispatch();

    const { currentLayer, selectedLayers } = useLayer();
    const contextMenu = useSelector(
      (state: RootState) => state.boardReducer.contextMenu
    );

    const handleClose = useCallback(() => {
      dispatch(setContextMenu(null));
    }, [dispatch]);

    const toggleField = useCallback(
      (field: string) => {
        handleClose();
        if (!currentLayer) return;

        dispatch(
          updateLayer({
            id: currentLayer.id,
            [field]: currentLayer[field as keyof BuilderLayerJSON] ? 0 : 1,
          })
        );
        focusBoardQuickly();
      },
      [dispatch, currentLayer, handleClose]
    );

    const handleDelete = useCallback(() => {
      handleClose();
      if (selectedLayers.length) onDeleteLayers(selectedLayers);
    }, [selectedLayers, onDeleteLayers, handleClose]);

    const handleClone = useCallback(() => {
      handleClose();
      if (currentLayer)
        onCloneLayer(currentLayer as BuilderLayerJSON<MovableObjLayerData>);
    }, [currentLayer, onCloneLayer, handleClose]);

    const handleCloneList = useCallback(() => {
      handleClose();
      if (selectedLayers.length)
        onCloneLayerList(
          selectedLayers as BuilderLayerJSON<MovableObjLayerData>[],
          {
            selectAllAfterCreate: true,
          }
        );
    }, [selectedLayers, onCloneLayerList, handleClose]);

    const handleCloneAndRotate = useCallback(() => {
      handleClose();
      if (currentLayer)
        onCloneLayer(currentLayer as BuilderLayerJSON<MovableObjLayerData>, {
          mirrorRotation: true,
        });
    }, [currentLayer, onCloneLayer, handleClose]);

    const handleGroupLayers = useCallback(() => {
      handleClose();
      if (selectedLayers.length > 1) {
        onGroupLayers(selectedLayers);
      }
    }, [handleClose, onGroupLayers, selectedLayers]);

    const handleUnGroupLayers = useCallback(() => {
      handleClose();
      if (currentLayer?.layer_type === LayerTypes.GROUP) {
        onUngroupLayer(currentLayer as BuilderLayerJSON<GroupObjLayerData>);
      }
    }, [currentLayer, handleClose, onUngroupLayer]);

    const handleRotate90 = useCallback(() => {
      handleClose();

      if (!currentLayer || !stageRef.current) return;

      const stage = stageRef.current;
      const selectedNode = stage.findOne("." + currentLayer.id);

      if (!selectedNode) return;

      let value = selectedNode.rotation() + 90;

      if (value <= -180) value += 360;
      else if (value >= 180) value -= 360;

      if (
        !isCenterBasedShape(
          (currentLayer.layer_data as ShapeBaseObjLayerData).type
        )
      ) {
        const newRot = (value / 180) * Math.PI;
        const boundBox = {
          x: selectedNode.x(),
          y: selectedNode.y(),
          width: selectedNode.width(),
          height: selectedNode.height(),
          rotation: (selectedNode.rotation() / 180) * Math.PI,
        };

        const newBoundBox = rotateAroundCenter(
          boundBox,
          newRot - boundBox.rotation
        );
        dispatch(
          updateLayer({
            id: currentLayer.id,
            layer_data: {
              ...currentLayer.layer_data,
              left: newBoundBox.x,
              top: newBoundBox.y,
              rotation: value,
            },
          } as Partial<BuilderLayerJSON<MovableObjLayerData>>)
        );
      } else {
        dispatch(
          updateLayer({
            id: currentLayer.id,
            layer_data: {
              ...currentLayer.layer_data,
              rotation: value,
            },
          } as Partial<BuilderLayerJSON<MovableObjLayerData>>)
        );
      }
    }, [currentLayer, dispatch, stageRef, handleClose]);

    const handleToggleFlop = useCallback(() => {
      handleClose();

      if (!currentLayer || !stageRef.current) return;

      const currentMovableLayerData = currentLayer.layer_data as MovableObjLayerData;

      const newFlop = currentMovableLayerData.flop ? 0 : 1;
      const rot = (currentMovableLayerData.rotation / 180) * Math.PI;
      const node = stageRef.current.find(`.${currentLayer.id}`)[0];
      const width =
        currentMovableLayerData.width ||
        (node &&
          node.getClientRect({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            relativeTo: node.getParent()!.getParent()!,
            skipShadow: true,
          }).width);
      dispatch(
        updateLayer({
          id: currentLayer.id,
          layer_data: {
            ...currentMovableLayerData,
            left:
              currentMovableLayerData.left +
              (newFlop ? 1 : -1) * Math.cos(rot) * width,
            top:
              currentMovableLayerData.top +
              (newFlop ? 1 : -1) * Math.sin(rot) * width,
            flop: newFlop,
          },
        } as Partial<BuilderLayerJSON<MovableObjLayerData>>)
      );
    }, [currentLayer, dispatch, stageRef, handleClose]);

    const handleToggleFlip = useCallback(() => {
      handleClose();

      if (!currentLayer || !stageRef.current) return;

      const currentMovableLayerData = currentLayer.layer_data as MovableObjLayerData;

      const newFlip = currentMovableLayerData.flip ? 0 : 1;
      const rot = (currentMovableLayerData.rotation / 180) * Math.PI;
      const node = stageRef.current.find(`.${currentLayer.id}`)[0];
      const height =
        currentMovableLayerData.height ||
        (node &&
          node.getClientRect({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            relativeTo: node.getParent()!.getParent()!,
            skipShadow: true,
          }).height);
      dispatch(
        updateLayer({
          id: currentLayer.id,
          layer_data: {
            ...currentMovableLayerData,
            left:
              currentMovableLayerData.left +
              (newFlip ? -1 : 1) * Math.sin(rot) * height,
            top:
              currentMovableLayerData.top +
              (newFlip ? 1 : -1) * Math.cos(rot) * height,
            flip: newFlip,
          },
        } as Partial<BuilderLayerJSON<MovableObjLayerData>>)
      );
    }, [currentLayer, dispatch, stageRef, handleClose]);

    useEffect(() => {
      if (!selectedLayers.length && contextMenu) {
        dispatch(setContextMenu(null));
      }
    }, [selectedLayers, contextMenu, dispatch]);

    if (!contextMenu || !selectedLayers.length) {
      return <></>;
    }

    return (
      <Popover
        open={!!contextMenu}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: contextMenu.y + wrapperPosition.y,
          left: contextMenu.x + wrapperPosition.x,
        }}
      >
        <Box display="flex" flexDirection="column" px={4} py={2} width="200px">
          <NameItem>
            {decodeHtml(
              currentLayer
                ? currentLayer.layer_data.name
                : `${selectedLayers.length} layers`
            )}
          </NameItem>
          {currentLayer ? (
            <>
              <StyledButton
                startIcon={<MdVisibilityOff />}
                onClick={() => toggleField("layer_visible")}
              >
                Hide
              </StyledButton>
              <StyledButton startIcon={<ImCopy />} onClick={handleClone}>
                Duplicate
              </StyledButton>
              <StyledButton
                startIcon={<MdRotateRight />}
                onClick={handleRotate90}
              >
                Rotate 90°
              </StyledButton>
              <StyledButton
                startIcon={<FlipIcon />}
                onClick={handleCloneAndRotate}
              >
                Mirror Clone
              </StyledButton>
              <StyledButton
                startIcon={<MdSwapHoriz />}
                onClick={handleToggleFlop}
              >
                Flop
              </StyledButton>
              <StyledButton
                startIcon={<MdSwapVert />}
                onClick={handleToggleFlip}
              >
                Flip
              </StyledButton>
              <StyledButton
                startIcon={<MdLock />}
                onClick={() => toggleField("layer_locked")}
              >
                Lock
              </StyledButton>
            </>
          ) : (
            <>
              <StyledButton
                startIcon={<LinkIcon />}
                onClick={handleGroupLayers}
              >
                Group
              </StyledButton>
              <StyledButton startIcon={<ImCopy />} onClick={handleCloneList}>
                Duplicate
              </StyledButton>
            </>
          )}

          <StyledDivider />
          {currentLayer?.layer_type === LayerTypes.GROUP ? (
            <StyledButton
              startIcon={<LinkOffIcon />}
              onClick={handleUnGroupLayers}
            >
              UnGroup
            </StyledButton>
          ) : (
            <></>
          )}
          <StyledButton startIcon={<MdDelete />} onClick={handleDelete}>
            Delete
          </StyledButton>
        </Box>
      </Popover>
    );
  }
);

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

const NameItem = styled(Typography)`
  font-size: 12px;
  font-family: AkkuratMonoLLWeb-Regular;
  font-weight: 500;
  color: lightgray;
  padding: 4px 0;
`;

const StyledButton = styled(Button)`
  font-size: 1rem;
  justify-content: start;
`;

export default BoardContextMenu;
