import { Box, Button } from "@material-ui/core";
import React, { useCallback } from "react";
import { focusBoardQuickly } from "src/helper";
import { useLayer } from "src/hooks";
import { CloneLayerListOptions } from "src/redux/reducers/layerReducer";
import { MovableObjLayerData } from "src/types/common";
import { LayerTypes } from "src/types/enum";
import { BuilderLayerJSON } from "src/types/query";

type MultiLayerPropertyProps = {
  editable: boolean;
  onGroup: (layers: BuilderLayerJSON[]) => void;
  onDelete: (layers: BuilderLayerJSON[]) => void;
  onCloneLayerList: (
    layers: BuilderLayerJSON<MovableObjLayerData>[],
    options?: CloneLayerListOptions
  ) => void;
};

export const MultiLayerProperty = React.memo(
  ({
    editable,
    onCloneLayerList,
    onDelete,
    onGroup,
  }: MultiLayerPropertyProps) => {
    const { selectedLayers } = useLayer();

    const includeLockedLayers = selectedLayers.some(
      (layer) => (layer.layer_data as MovableObjLayerData)?.editLock
    );
    const includeGroupLayers = selectedLayers.some(
      (layer) => layer.layer_type === LayerTypes.GROUP
    );

    const handleGroup = useCallback(() => {
      if (selectedLayers.length > 1) onGroup(selectedLayers);
      focusBoardQuickly();
    }, [onGroup, selectedLayers]);

    const handleClone = useCallback(() => {
      if (selectedLayers.length > 1)
        onCloneLayerList(
          selectedLayers as BuilderLayerJSON<MovableObjLayerData>[],
          {
            selectAllAfterCreate: true,
          }
        );
      focusBoardQuickly();
    }, [onCloneLayerList, selectedLayers]);

    const handleDelete = useCallback(() => {
      if (selectedLayers.length) onDelete(selectedLayers);
      focusBoardQuickly();
    }, [onDelete, selectedLayers]);

    return (
      <Box display="flex" flexDirection="column" width="100%">
        {editable ? (
          <Button
            variant="outlined"
            disabled={includeLockedLayers}
            onClick={handleGroup}
          >
            {includeGroupLayers ? "Merge Group" : "Group"}
          </Button>
        ) : (
          <></>
        )}
        {editable ? (
          <Box width="100%" mt={2}>
            <Button
              variant="outlined"
              fullWidth
              disabled={includeLockedLayers}
              onClick={handleClone}
            >
              Clone
            </Button>
          </Box>
        ) : (
          <></>
        )}
        {editable ? (
          <Box width="100%" mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              disabled={includeLockedLayers}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  }
);
