import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PressedKey } from "src/constant";
import { getCloningLayerID } from "src/helper";
import { RootState } from "src/redux";
import {
  setFrameSizeToMax,
  setShowProperties,
} from "src/redux/reducers/boardReducer";
import {
  cloneLayer,
  deleteCloningLayersByID,
  deleteCloningQueueByID,
  deleteSelectedLayerId,
  insertToCloningQueue,
  mergeListItem as updateLayerOnly,
  setLoadedStatus,
  setSelectedLayerIds,
  updateLayer,
} from "src/redux/reducers/layerReducer";
import {
  DefaultLayerData,
  FrameSize,
  MovableObjLayerData,
  PartialAllLayerData,
} from "src/types/common";
import { BuilderLayerJSON } from "src/types/query";

export const useLayer = () => {
  const dispatch = useDispatch();

  const frameSize = useSelector(
    (state: RootState) => state.boardReducer.frameSize
  );
  const pressedKey = useSelector(
    (state: RootState) => state.boardReducer.pressedKey
  );
  const layerList = useSelector((state: RootState) => state.layerReducer.list);
  const loadedStatuses = useSelector(
    (state: RootState) => state.layerReducer.loadedStatuses
  );
  const cloningLayers = useSelector(
    (state: RootState) => state.layerReducer.cloningLayers
  );
  const cloningQueue = useSelector(
    (state: RootState) => state.layerReducer.cloningQueue
  );
  const selectedLayerIds = useSelector(
    (state: RootState) => state.layerReducer.selectedLayerIds
  );

  const currentLayer = useMemo(
    () =>
      selectedLayerIds.length === 1
        ? layerList.find((layer) => layer.id === selectedLayerIds[0])
        : null,
    [selectedLayerIds, layerList]
  );
  const selectedLayers = useMemo(
    () => layerList.filter((layer) => selectedLayerIds.includes(layer.id)),
    [layerList, selectedLayerIds]
  );

  const onLoadLayer = useCallback(
    (layerID: string | number, flag: boolean) => {
      dispatch(setLoadedStatus({ key: layerID, value: flag }));
    },
    [dispatch]
  );

  const onExpandFrameFromImage = useCallback(
    (size: FrameSize) => {
      if (frameSize.width < size.width || frameSize.height < size.height) {
        dispatch(
          setFrameSizeToMax({
            width: Math.max(frameSize.width, size.width),
            height: Math.max(frameSize.height, size.height),
          })
        );
      }
    },
    [dispatch, frameSize]
  );

  const onLayerDataChange = useCallback(
    (
      layer: BuilderLayerJSON,
      values: PartialAllLayerData,
      pushingToHistory = true
    ) => {
      dispatch(
        updateLayer(
          {
            id: layer.id,
            layer_data: {
              ...values,
            },
          } as Partial<BuilderLayerJSON>,
          { pushingToHistory }
        )
      );
    },
    [dispatch]
  );

  const onLayerDataChangeOnly = useCallback(
    (layer: BuilderLayerJSON, values: DefaultLayerData) => {
      dispatch(
        updateLayerOnly({
          id: layer.id,
          layer_data: {
            ...values,
          },
        })
      );
    },
    [dispatch]
  );

  const onDblClickLayer = useCallback(() => {
    dispatch(setShowProperties(true));
  }, [dispatch]);

  const onLayerSelect = useCallback(
    (layer: BuilderLayerJSON<MovableObjLayerData>) => {
      const metaPressed =
        pressedKey === PressedKey.Ctrl ||
        pressedKey === PressedKey.Meta ||
        pressedKey === PressedKey.Shift;

      if (layer.layer_data.editLock) {
        return;
      }

      if (!metaPressed) {
        // Single Layer Selecting here
        dispatch(setSelectedLayerIds([layer.id]));
        return;
      }

      // Multi-layer Selectings here
      let ids = [...selectedLayerIds];
      if (selectedLayerIds.includes(layer.id)) {
        ids = ids.filter((item) => item !== layer.id);
      } else {
        ids = [...ids, layer.id];
      }

      dispatch(setSelectedLayerIds(ids));
    },
    [dispatch, pressedKey, selectedLayerIds]
  );

  const onCloneMoveLayer = useCallback(
    (movedLayer: BuilderLayerJSON<MovableObjLayerData>) => {
      const cloningLayerID = getCloningLayerID(movedLayer);
      const newQueueID = getCloningLayerID(movedLayer) + "queue";
      dispatch(deleteCloningLayersByID(cloningLayerID));
      dispatch(insertToCloningQueue({ ...movedLayer, id: newQueueID }));
      dispatch(deleteSelectedLayerId(movedLayer.id));
      dispatch(
        cloneLayer(movedLayer, {
          samePosition: true,
          pushingToHistory: true,
          keepPreviousSelect: true,
          callback: () => {
            dispatch(deleteCloningQueueByID(newQueueID));
          },
        })
      );
    },
    [dispatch]
  );

  return {
    currentLayer,
    layerList,
    selectedLayerIds,
    selectedLayers,
    loadedStatuses,
    cloningLayers,
    cloningQueue,
    onLoadLayer,
    onLayerDataChange,
    onLayerDataChangeOnly,
    onLayerSelect,
    onCloneMoveLayer,
    onDblClickLayer,
    onExpandFrameFromImage,
  };
};
